import { theme as defaultTheme, Theme } from "@hurleymc/components"

const colors = {
  hurleyGme: "#E08700",
}

const theme: Partial<Theme> = {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    default: colors.hurleyGme,
    primary: colors.hurleyGme,
  },
}

export default theme
